"use client"

import { useThemeContext } from '@/app/theme-provider';
import BlockUi from '@availity/block-ui';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from "react"


export default function TopBar() {

    const { userIP } = useThemeContext();

    const [loading, setLoading] = useState(true)

    const [protectedStatus, setProtectedStatus] = useState(false)

    useEffect(() => {

        const fetchData = async () => {

            if (userIP.query !== "127.0.0.1") {
                const response = await fetch('/api/get_server_ips');
                const data = await response.json();
                setProtectedStatus(data.includes(userIP.query));
            }

            setLoading(false)
        }

        // if (usePathname() !== "/deals/halloween/")
        fetchData();

    }, [])

    return (
        <>
            {(!usePathname().startsWith("/deals/lifetime")) &&
                <BlockUi tag="span" className="d-block" blocking={loading}>
                    <div className="topbar">
                        <p className="mb-0">Your IP: <span id="my-ip-address">{userIP.query}</span> • ISP: {userIP.as}
                         {/* • Your Status: <span className={protectedStatus ? "text-success" : "text-danger"} id="protected">{protectedStatus ? 'Protected' : 'Unprotected'}</span> */}
                         </p>
                    </div>
                </BlockUi>
            }
        </>
    )
}