"use client"

import Image from "next/image";
import Link from "next/link"
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import styles from '../app/(front)/deals/lifetime/default.module.css'
import { useThemeContext } from "@/app/theme-provider";

export default function Header({ defaultTheme }) {

    const [isSticky, setSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const { userInfo } = useThemeContext();

    const pathname = usePathname();

    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

    const toggleSubMenu = (index, event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor link
        setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleScroll = () => setSticky(window.scrollY > 10 ?? false)

    const handleResponsiveMenu = () => {
        setIsMenuOpen(!isMenuOpen)

        // if (!isMenuOpen) {
        //     document.querySelector("body").classList.add("overflow-hidden")
        // } else {

        //     document.querySelector("body").classList.remove("overflow-hidden")
        // }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {!pathname.startsWith("/deals/14") &&
                <header className={["header", (isSticky && !pathname.startsWith("/deals/lifetime") && pathname !== "/buy-vpn/" && pathname !== "/deals/black-friday/" ? "header-fixed stickyHeader-slideDown" : null), (pathname !== '/' && (!pathname.startsWith("/deals/lifetime")) ? "header-inner" : styles.headerTop)].join(" ")} >
                    {/* <header className={"header" + (pathname !== '/' ? " header-inner" : '')}> */}
                    <div className="container">
                        <div className="main-header mt-2">
                            <div className="row align-items-center mt-3">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="logo-wrap">
                                        <Link href="/" className={`logo ${defaultTheme}`}></Link>

                                        {(!pathname.startsWith("/deals/") && !pathname.startsWith("/vpn-offer/")) ?
                                            <div className="responsive-menu-area">
                                                {userInfo?.user_id == null ?
                                                    <Link href="/buy-vpn/" className="navbt navbt-blue">Buy Now</Link>
                                                    :
                                                    <Link href="/my-account/" className="navbt navbt-blue">My Account</Link>
                                                }
                                                <button className="responsive-menu-btn" onClick={handleResponsiveMenu}>
                                                    <i className="fas fa-bars"></i>
                                                </button>
                                            </div>
                                            :
                                            <Link href="https://support.oystervpn.com" className="navbt navbt-blue d-lg-none d-md-block d-sm-none">
                                                <i className="fas fa-headset"></i>&nbsp;
                                                Support
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                    <div className={`header-nav-menus header-nav-menu-${defaultTheme} align-items-center`}>
                                        <ul className={["nav-menu-list list-unstyled mb-0", ((pathname === '/buy-vpn/' || pathname.startsWith("/deals/") || pathname.startsWith("/vpn-offer/")) ? " d-none" : "")].join(" ")}>
                                            <li className="mydropdown">
                                                <Link href="" className="mydropdown-link">Why VPN?</Link>
                                                <div className="mydropdown-menu mydropdown-menu-vpn">
                                                    <div className="hd-dd-wrap">
                                                        <div className="hd-dd-nav">
                                                            <ul className="list-untyled mb-0">
                                                                <li><Link href="/what-is-a-vpn/">What is VPN?</Link></li>
                                                                <li><Link href="/what-is-a-vpn/secure-vpn/">VPN for Security</Link></li>
                                                                <li><Link href="/what-is-a-vpn/wifi-safety/">VPN for Wi-Fi</Link></li>
                                                                <li><Link href="/unblock-websites/">VPN for Unblocking Websites</Link></li>
                                                                <li><Link href="/streaming/">VPN for Streaming</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><Link href="/features">Features</Link></li>
                                            <li className="mydropdown mydropdown-apps">
                                                <Link href="/download" className="mydropdown-link">Apps</Link>
                                                <div className="mydropdown-menu mydropdown-menu-apps">
                                                    <div className="hd-dd-wrap">
                                                        <div className="hd-dd-nav">
                                                            <div className="hd-dd-head">
                                                                <span>VPN Apps</span>
                                                            </div>
                                                            <ul className="list-untyled mb-0">
                                                                <li><Link href="/download/windows-vpn/"><i className="fab fa-windows"></i> <span>Windows</span></Link></li>
                                                                <li><Link href="/download/mac-vpn/"><i className="fab fa-apple"></i> <span>MacOS</span></Link></li>
                                                                <li><Link href="/download/linux-vpn/"><i className="fab fa-linux"></i> <span>Linux</span></Link></li>
                                                                <li><Link href="/download/ios-vpn/"><i className="fab fa-apple"></i> <span>iOS</span></Link></li>
                                                                <li><Link href="/download/android-vpn/"><i className="fab fa-android"></i> <span>Android</span></Link></li>
                                                                <li><Link href="/download/fire-tv-vpn/"><i className="fas fa-tv"></i> <span>FireTV</span></Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="hd-dd-nav">
                                                            <div className="hd-dd-head">
                                                                <span>Browser extensions</span>
                                                            </div>
                                                            <ul className="list-untyled mb-0">
                                                                <li><Link href="/download/chrome-vpn/"><i className="fab fa-chrome"></i> <span>Chrome </span></Link></li>
                                                                <li><Link href="/download/firefox-vpn/"><i className="fab fa-firefox"></i> <span>Firefox </span></Link></li>
                                                                <li><Link href="/download/edge-vpn/"><i className="fab fa-edge"></i> <span>Edge <small>(coming soon)</small></span></Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="hd-dd-nav">
                                                            <div className="hd-dd-head">
                                                                <span>Tools</span>
                                                            </div>
                                                            <ul className="list-untyled mb-0">
                                                                <li><Link href="/what-is-my-ip/"><span>What is my IP?</span></Link></li>
                                                                <li><Link href="/dns-leak-test/"><span>DNS Leak Test</span></Link></li>
                                                                {/* <li><Link href="/webrtc-leak-test/"><span>WebRTC Leak Test</span></Link></li> */}
                                                                {/* <li><Link href="#"><span>Password Generator</span></Link></li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><Link href="/servers/">Servers</Link></li>
                                            <li><Link href="/buy-vpn/">Pricing</Link></li>
                                            <li><a href="https://oystervpn.com/blog/">Blog</a></li>
                                            <li><Link href="https://support.oystervpn.com/">Support</Link></li>
                                        </ul>
                                        <div className="navbtn-wrap">
                                            {pathname.startsWith("/deals/") ?
                                                <>
                                                    <Link href="https://support.oystervpn.com" className="navbt navbt-blue">
                                                        <i className="fas fa-headset"></i>&nbsp;
                                                        Support
                                                    </Link>
                                                    {userInfo?.user_id == null ?
                                                        <Link href="/auth/login/" className={`navbt ${defaultTheme}`}>
                                                            <i className="fas fa-lock"></i>&nbsp;
                                                            Login
                                                        </Link>
                                                        :
                                                        <Link href="/my-account/" className={`navbt ${defaultTheme}`}>
                                                            <i className="fas fa-user"></i>&nbsp;
                                                            My Account
                                                        </Link>
                                                    }
                                                </>
                                                :
                                                pathname !== '/buy-vpn/' && (
                                                    <>
                                                        {userInfo?.user_id == null ?
                                                            <>
                                                                <Link href="/buy-vpn/" className="navbt navbt-blue">Buy Now</Link>
                                                                <Link href="/auth/login/" className="navbt">Login</Link>
                                                            </>
                                                            :
                                                            <>
                                                                <Link href="/my-account/" className="navbt navbt-blue">My Account</Link>
                                                                <Link href="/my-account/logout/" className="navbt">Logout</Link>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            }

            <div className={["siderbarmenu", isMenuOpen ? "siderbarmenu-open" : ""].join(" ")} id="responsive-menu">
                <div className="sidebm-inner">
                    <div className="sidebm-head">
                        <button className={["sidebm-close"].join(" ")} onClick={handleResponsiveMenu}>&times;</button>
                        <Link href="#" className="sidebm-logo">
                            <Image width={160} height={36} src="/assets/images/logo.svg" title="OysterVPN" alt="OysterVPN" className="img-fluid" />
                        </Link>
                    </div>
                    <div className="sidebm-nav-menus">
                        <ul className="sidebm-menu-list list-unstyled mb-0">
                            <li className="sidebm-dropdown">
                                <a className="sidebm-dropdown-link">Why VPN?</a>
                                <span id="vpnIcon" className="dropdown-icon" onClick={(event) => toggleSubMenu(1, event)}>
                                    {openSubMenuIndex === 1 ? '\uF077' : '\uF078'}
                                </span>
                                <ul className={`sidebm-submenu list-unstyled mb-0 ${openSubMenuIndex === 1 ? 'open' : ''}`} >
                                    <li><Link href="/what-is-a-vpn/" onClick={handleResponsiveMenu}>What is VPN?</Link></li>
                                    <li><Link href="/what-is-a-vpn/secure-vpn/" onClick={handleResponsiveMenu}>VPN for Security</Link></li>
                                    <li><Link href="/what-is-a-vpn/wifi-safety/" onClick={handleResponsiveMenu}>VPN for Wi-Fi</Link></li>
                                    <li><Link href="/unblock-websites/" onClick={handleResponsiveMenu}>VPN for Unblocking Websites</Link></li>
                                    <li><Link href="/streaming/" onClick={handleResponsiveMenu}>VPN for Streaming</Link></li>
                                </ul>
                            </li>
                            <li><Link href="/features/">Features</Link></li>
                            <li className="sidebm-dropdown">
                                <Link className="sidebm-dropdown-link" onClick={handleResponsiveMenu} href="/download/">Apps</Link>

                                <span id="vpnIcon" className="dropdown-icon" onClick={(event) => toggleSubMenu(2, event)}>
                                    {openSubMenuIndex === 2 ? '\uF077' : '\uF078'}
                                </span>
                                <ul className={`sidebm-submenu list-unstyled mb-0 ${openSubMenuIndex === 2 ? 'open' : ''}`}>
                                    <li>
                                        <span>VPN Apps</span>
                                        <ul className="list-untyled mb-0">
                                            <li><Link href="/download/windows-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-windows"></i> <span>Windows</span></Link></li>
                                            <li><Link href="/download/mac-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-apple"></i> <span>MacOS</span></Link></li>
                                            <li><Link href="/download/linux-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-linux"></i> <span>Linux</span></Link></li>
                                            <li><Link href="/download/ios-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-apple"></i> <span>iOS</span></Link></li>
                                            <li><Link href="/download/android-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-android"></i> <span>Android</span></Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Browser extensions</span>
                                        <ul className="list-untyled mb-0">
                                            <li><Link href="/download/chrome-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-chrome"></i> <span>Chrome </span></Link></li>
                                            <li><Link href="/download/firefox-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-firefox"></i> <span>Firefox</span></Link></li>
                                            <li><Link href="/download/edge-vpn/" onClick={handleResponsiveMenu}><i className="fab fa-edge"></i> <span>Edge</span></Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Tools</span>
                                        <ul className="list-untyled mb-0">
                                            <li><Link href="/what-is-my-ip/" onClick={handleResponsiveMenu}><span>What is my IP?</span></Link></li>
                                            <li><Link href="/dns-leak-test/" onClick={handleResponsiveMenu}><span>DNS Leak Test</span></Link></li>
                                            {/* <li><Link href="/webrtc-leak-test/"><span>WebRTC Leak Test</span></Link></li> */}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><Link href="/servers/" onClick={handleResponsiveMenu}>Servers</Link></li>
                            <li><Link href="/buy-vpn/" onClick={handleResponsiveMenu}>Pricing</Link></li>
                            <li><a href="/blog/">Blog</a></li>
                            <li><Link href="https://support.oystervpn.com/">Support</Link></li>
                        </ul>
                        <div className="sidebm-btn-wrap">
                            {userInfo?.user_id == null ?
                                <Link href="/auth/login/" onClick={handleResponsiveMenu} className="sidebm-btn"><i className="fas fa-sign-in-alt"></i> Sign In</Link>
                                :
                                <Link href="/my-account/logout/" onClick={handleResponsiveMenu} className="sidebm-btn" style={{marginBottom: "13px"}}><i className="fas fa-sign-out-alt"></i> Logout</Link>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}